//Dumb component

import React, {Component} from 'react';
import { Route, Switch } from "react-router-dom";
import './app_content.module.css';
import '../../assets/css/bootstrap.css';
import Home from "../home/Home";
import Filmes from "../filmes/Filmes";
import FilmesDetalhes from "../filmes-detalhes/FilmesDetalhes";
import Sobre from "../sobre/Sobre";
import Blog from "../blog/Blog";
import BlogSingle from "../blog-single/BlogSingle";
import Loja from "../loja/Loja";
import Guara from "../guara/Guara";
import Contato from "../contato/Contato";
import PoliticaPrivacidade from "../politica-de-privacidade/PoliticaPrivacidade";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Buscas from "../buscas/Buscas";
import api from '../../services/api';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-113669819-1');
ReactGA.pageview(window.location.path + window.location.search);

export default class AppContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            movies: [],
            carousel: [],
            sobre: [],
            blog: [],
            modalShow: false,
            closeMenu: "",
            lang: "en",
        };

        this.modalClickShow = this.modalClickShow.bind(this);
        this.modalClickClose = this.modalClickClose.bind(this);
        this.changeLang = this.changeLang.bind(this);

        this.getLang();
    }

    async getLang() {
        const lang = await localStorage.getItem("lang");

        switch(lang) {
            case "en":
                await this.setState({lang: "pt"});
                break;
            default:
                await localStorage.setItem("lang", "pt");
                break;
        }

        this.getInfo();
    }

     async getInfo() {
        const movies = await api.get('/movies/index/' + localStorage.getItem("lang"));
        const carousel = await api.get('/pages/show/home/' + localStorage.getItem("lang"));
        const sobre = await api.get('/pages/show/sobre/' + localStorage.getItem("lang"));
        let blog = await api.get('/pages/show/blog/' + localStorage.getItem("lang"));
        blog.data.content.files= blog.data.content.files.sort((a,b) => parseInt(a.order)-parseInt(b.order));
        this.setState({
            movies,
            carousel,
            sobre,
            blog,
        });
    
    }

    modalClickShow(e) {
        this.setState({ modalShow: true });
    }

    modalClickClose(e) {
        this.setState({ modalShow: false });
    }

    async changeLang(e) {
        if(localStorage.getItem("lang") === "pt") {
            await localStorage.setItem("lang", "en");
            await this.setState({lang: "pt"})
        }else{
            await localStorage.setItem("lang", "pt");
            await this.setState({lang: "en"})
        }
        this.getInfo();
    }

    render() {

        return(
            <div>
                <Header {...this.state} lang={this.state.lang} changeLang={this.changeLang} modalClickShow={this.modalClickShow} modalClickClose={this.modalClickClose} />
                <Switch>
                    <Route path='/' exact component={() => <Home carousel={this.state.carousel.data} movies={this.state.movies} />} />
                    <Route path='/filmes' component={() => <Filmes movies={this.state.movies} />} />
                    <Route path='/filmes-detalhes/:slug' component={(props) => <FilmesDetalhes props={props} movies={this.state.movies} />} />
                    <Route path='/sobre' exact component={() => <Sobre sobre={this.state.sobre} />} />
                    <Route path='/blog' exact component={() => <Blog blog={this.state.blog} />} />
                    <Route path='/blog-single/:slug' component={(props) => <BlogSingle props={props} blog={this.state.blog} />} />
                    <Route path='/loja' component={Loja} />
                    <Route path='/guara' component={Guara} />
                    <Route path='/contato' component={Contato} />
                    <Route path='/politica-de-privacidade' component={(props) => <PoliticaPrivacidade lang={this.state.lang} props={props} />} />
                    <Route path='/buscas/:s' component={Buscas} />
                </Switch>
                <Footer/>
            </div>
        );
    }

}
