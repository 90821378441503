import React, { Component } from 'react';
import styles from './Contato.module.scss';
import { Container, Row, Col } from 'react-grid-system';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import api from '../../services/api';

import {
  translate,
} from 'react-switch-lang';

class Contato extends Component {

  state = {
    first_name: "",
    email: "",
    body: "",
    success: "",
    errors: ""
  }

  onChangeInput(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  /*
    async getMessageError(fields = [], errors) {
      fields.map(input => {
          return errors.response.data[input].map(error => {
              return this.setState({
                  errors: [...this.state.errors, error]
              })
          })
      })
  }*/


  async sendMail(e) {
    e.preventDefault();
    const { first_name, email, body } = this.state;

    const data = {
      first_name,
      email,
      body,
      'type': 1
    }

    if (first_name.length < 3) {
      this.setState({ errors: this.props.t('errors.nomeInvalido', null, localStorage.getItem("lang")) })
      return;
    }

    if (body.length < 3) {
      this.setState({ errors: this.props.t('errors.mensagemInvalida', null, localStorage.getItem("lang")) })
      return;
    }

    await this.setState({ errors: "", success: "" });

    await api.post('/contacts/store', data)
      .then(() => {
        this.setState({ success: this.props.t('sucesso.mensagemSucesso', null, localStorage.getItem("lang")) })
      })
      .catch((errors) => {
        this.setState({ errors: this.props.t('errors.emailInvalido', null, localStorage.getItem("lang")) })
        //await this.getMessageError(['first_name', 'email', 'body'], errors);
      })
  }

  render() {

    const { t } = this.props;

    return (
      <section className={styles.content_dt}>
        <h1 className={styles.noth1}>{t('footer.contato', null, localStorage.getItem("lang"))}</h1>
        <div className={styles.content_dtfilmes}>
          <Container fluid>
            <Row>
              <Col md={12}>
                <div className={styles.wrapper}>
                  <h2>Deixe seu recado abaixo e logo entraremos em contato !</h2>
                  <Form>
                    <Form.Group controlId="formBasicText">
                      <Form.Control name="first_name" type="text" placeholder={t('contato.nome', null, localStorage.getItem("lang"))} value={this.state.first_name} onChange={e => this.onChangeInput(e)} />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control name="email" type="email" placeholder="E-MAIL" value={this.state.email} onChange={e => this.onChangeInput(e)} />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Control name="body" as="textarea" rows="3" placeholder={t('contato.mensagem', null, localStorage.getItem("lang"))} value={this.state.body} onChange={e => this.onChangeInput(e)} />
                    </Form.Group>
                    <Form.Group>
                      <Button variant="primary" type="submit" className={styles.btn_black} onClick={(e) => this.sendMail(e)}>
                        {t('contato.enviar', null, localStorage.getItem("lang"))}
                      </Button>
                    </Form.Group>
                  </Form>

                  <div>
                    {this.state.errors &&
                      <div className="alert alert-danger">
                        <ul>
                          <li>{this.state.errors}</li>
                        </ul>
                      </div>
                    }

                    {this.state.success &&
                      <div className="alert alert-success">
                        <ul>
                          <li>{this.state.success}</li>
                        </ul>
                      </div>
                    }
                  </div>

                  <div className={styles.dados}>
                    <h2>Downtown Filmes</h2>
                    <p>
                      {/* {t('contato.endereco', null, localStorage.getItem("lang"))} */}
                      <span>Avenida das Américas 5OO Bloco I6 B Sala 3I3</span>
                      <span> Barra da Tijuca - Rio de Janeiro CEP 2264O-1OO</span>
                      <span>-</span>
                      <span>Telefone: +55 2I 36I3-26OO</span>
                      <span>Ramal da Programação: 2608</span>
                      <span>Ramal do Financeiro: 2605</span>
                    </p>
                  </div>

                  <div className={styles.dados}>
                    <h3>Demais assuntos, seguem nossos contatos:</h3>
                    <p>
                      <span>Programação de filmes: <strong>programacao@dtfilmes.com.br</strong></span>
                      <span>Gerencia de projetos: <strong>projetos@dtfilmes.com.br</strong></span>
                      <span>Marketing: <strong>mkt@dtfilmes.com.br</strong></span>
                      <span>Financeiro: <strong>financeiro@dtfilmes.com.br</strong></span>
                      <span>TI: <strong>ti@dtfilmes.com.br</strong></span>
                    </p>
                  </div>

                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    );
  }

}
export default translate(Contato);