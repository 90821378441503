import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import './ModalNews.css';

import api from '../../services/api';

import {
    translate,
} from 'react-switch-lang';

class ModalNews extends Component {

    state = {
        nome: "", //jonas
        sobrenome: "", //jonas
        email: "",
        success: "",
    }

    onChangeInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async getMessageError(fields = [], errors) {
        fields.map(input => {
            return errors.response.data[input].map(error => {
                return this.setState({
                    errors: [...this.state.errors, error]
                })
            })
        })
    }

    async sendNews(e) {
        e.preventDefault();
        const { nome, sobrenome, email } = this.state;

        const data = {
            nome,
            sobrenome,
            email,
            'type': 2
        }

        await this.setState({ errors: "", success: "" });

        await api.post('/contacts/store', data)
            .then(() => {
                this.setState({ success: this.props.t('sucesso.mensagemSucesso', null, localStorage.getItem("lang")) })
            })
            .catch(async (errors) => {
                this.setState({ errors: this.props.t('errors.emailInvalido', null, localStorage.getItem("lang")) })
            })
    }

    render() {
        const { t } = this.props;
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal_dt_news"
            >
                <Modal.Header closeButton>
                    <span></span>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('modal.quer', null, localStorage.getItem("lang"))}
                        <strong>
                            {t('modal.ingressos', null, localStorage.getItem("lang"))}
                            <br />
                            {t('modal.coisas', null, localStorage.getItem("lang"))}
                        </strong>
                        <br />
                        {t('modal.mais', null, localStorage.getItem("lang"))}
                    </p>
                    <form>
                        <div class="row">
                            <div>
                            <input value={this.state.nome} onChange={e => this.onChangeInput(e)} type="text" name="nome" id="nome" autoComplete="off" placeholder="nome"
                             onfocus="this.placeholder=''" onblur="this.placeholder='nome'" />
                            </div>
                            <div>
                            <input value={this.state.sobrenome} onChange={e => this.onChangeInput(e)} type="text" name="sobrenome" id="sobrenome" autoComplete="off" placeholder="sobrenome"
                             onfocus="this.placeholder=''" onblur="this.placeholder='sobrenome'" />
                            </div>
                        </div>
                        <input value={this.state.email} onChange={e => this.onChangeInput(e)} type="email" name="email" id="email" autoComplete="off" placeholder={t('modal.email_placeholder', null, localStorage.getItem("lang"))} />
                        <button onClick={e => this.sendNews(e)}>{t('contato.enviar', null, localStorage.getItem("lang"))}</button>
                    </form>
                    {this.state.errors &&
                        <div className="alert alert-danger">
                            {this.state.errors}
                        </div>
                    }

                    {this.state.success &&
                        <div className="alert alert-success">
                            {this.state.success}
                        </div>
                    }
                </Modal.Body>
            </Modal>
        );
    }
};

export default translate(ModalNews);